import { GetComponentByName, GetLowestPrice } from "./query-data";
import { SortBy } from "./constants";
import { GQLProduct } from "../types";

/* Returns an object used to build a one-level breadcrumb
 * - name -> A string representing the last crumb
 */
export const GetSimplePathObj = (name: string): Record<string, string> => {
  const PathObj: Record<string, string> = {};

  PathObj.Home = "/";
  PathObj[name] = null;

  return PathObj;
};

/* Sorts products on the product grid
 *
 */
export const SortGridItems = (
  gridItems: GQLProduct[],
  sortProperty: string,
  sortDirection: string
): void => {
  gridItems.sort((itemA, itemB) => {
    const isAscending = sortDirection === SortBy.AscendingValue;

    /* Sort products by name */
    if (sortProperty === SortBy.NameValue) {
      if (itemA.name < itemB.name) {
        return !isAscending ? 1 : -1;
      } else if (itemA.name > itemB.name) {
        return !isAscending ? -1 : 1;
      } else {
        return 0;
      }

      /* Sort products by price */
    } else if (sortProperty === SortBy.PriceValue) {
      const priceCompA = GetComponentByName(itemA.components, SortBy.PriceText);
      const priceCompB = GetComponentByName(itemB.components, SortBy.PriceText);
      const itemAPrice = GetLowestPrice(priceCompA);
      const itemBPrice = GetLowestPrice(priceCompB);

      if (itemAPrice < itemBPrice) {
        return !isAscending ? 1 : -1;
      } else if (itemAPrice > itemBPrice) {
        return !isAscending ? -1 : 1;
      } else {
        return 0;
      }

      /* Sort products by popularity */
    } else if (sortProperty === SortBy.PopularityValue) {
      type popType = { popularity: number };
      const ItemAPopularity = ((itemA as unknown) as popType).popularity;
      const ItemBPopularity = ((itemB as unknown) as popType).popularity;

      if (ItemAPopularity > ItemBPopularity) {
        return isAscending ? 1 : -1;
      } else if (ItemAPopularity < ItemBPopularity) {
        return isAscending ? -1 : 1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  });
};
