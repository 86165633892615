import React from "react";
import Layout from "../components/layout";
import Breadcrumb from "../components/breadcrumb";
import GlobalConstants from "../helpers/constants";
import Axios from "axios";
import { CrystallizeBaseURL } from "../helpers/constants";
import { Grid, Typography } from "@material-ui/core";
import { GetComponentByName } from "../helpers/query-data";
import { GetSimplePathObj } from "../helpers/data-formatting";
import { GQLQuery, GQLRichTextContent } from "../types";

const MyQuery = `
  {
    catalogue(path: "/articles/terms-and-conditions") {
      ...TAC_item
    }
  }

  fragment TAC_item on Item {
    name
    components {
      ...TAC_component
    }
  }

  fragment TAC_component on Component {
    name
    content {
      ...TAC_singleLine
      ...TAC_richText
    }
  }

  fragment TAC_singleLine on SingleLineContent {
    text
  }

  fragment TAC_richText on RichTextContent {
    html
  }
`;

const Terms = (): JSX.Element => {
  const [QueryResult, SetQueryResult] = React.useState({} as GQLQuery);
  const PathObj = GetSimplePathObj(GlobalConstants.TermsAndConditions);

  Axios.post(CrystallizeBaseURL, {
    query: MyQuery,
  }).then((response) => {
    SetQueryResult(response.data.data as GQLQuery);
  });

  const ContentCompContent = GetComponentByName(
    QueryResult.catalogue?.components ?? [],
    GlobalConstants.Content
  )?.content as GQLRichTextContent;

  return (
    <Layout>
      <Breadcrumb pathObj={PathObj} />
      <Grid item xs={12}>
        <Typography variant="h6">{QueryResult.catalogue?.name}</Typography>
        {ContentCompContent?.html.map((node, index) => (
          <div
            key={index}
            className="MuiTypography-root MuiTypography-body2"
            dangerouslySetInnerHTML={{ __html: node }}
          />
        ))}
      </Grid>
    </Layout>
  );
};

export default Terms;
